import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/*====== components =======*/
import Connect from "./screens/Connect";
import Home from "./screens/Home";

import Collection from "./components/collection";
import Staking from "./components/staking";
// import Leaderboard from "./components/leaderboard";
import ConfirmStake from "./components/staking/ConfirmStake";
import ConfirmUnstake from "./components/staking/ConfirmUnstake";
import ConfirmLegendaryStake from "./components/staking/ConfirmLegendaryStake";
import ConfirmLegendaryUnstake from "./components/staking/ConfirmLegendaryUnstake";

import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { getCurrentWalletConnected } from "./Utilities/Web3Utilities";
import { useDispatch } from "react-redux";

const App = () => {
  const dispatch = useDispatch();

  async function getJungleFreaks() {
    getCurrentWalletConnected().then((res) => {
      res.address !== "" &&
        fetch(
          "https://api.opensea.io/api/v1/assets?asset_contract_address=0x7E6Bc952d4b4bD814853301bEe48E99891424de0&owner=" +
          res.address
        )
          .then((res) => res.json())
          .then((data) => {

            const items = data.assets.map(item => ({
              "id": item.id,
              "contract": item.asset_contract.address,
              "tokenId": item.token_id,
              "creators": [
                {
                  "account": item.owner.address,
                  "value": 10000
                }
              ],
              "supply": "1",
              "lazySupply": "0",
              "lastUpdatedAt": "2021-10-15T22:34:17Z",
              "mintedAt": "2021-10-15T22:34:17Z",
              "deleted": false
            }))

            dispatch({
              type: "SET_UNSTAKEDTOKEN",
              payload: items
            });
          });
    });
    // await fetch(
    //   "https://ethereum-api.rarible.org/v0.1/nft/items/byCollection/?collection=0x7E6Bc952d4b4bD814853301bEe48E99891424de0&size=10000"
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     dispatch({ type: "ADD_TOTAL_ITEM", payload: data.items });
    //   })
    //   .catch((err) => console.log(err));
  }

  // const loadLeaderboardData = async () => {
  //   const { address } = await getCurrentWalletConnected();
  //   dispatch({ type: "SET_ACCOUNT", payload: address });

  //   await fetch(
  //     "https://api.ethplorer.io/getTopTokenHolders/0x7e6bc952d4b4bd814853301bee48e99891424de0?apiKey=freekey&limit=20"
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       dispatch({ type: "SET_TOP_HOLDERS", payload: data.holders });
  //     })
  //     .catch((err) => console.log(err));
  // };

  useEffect(() => {
    setInterval(() => {
      getJungleFreaks();
    }, 3000);

    // loadLeaderboardData();
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Connect />} />
          <Route path="/home" element={<Home />}>
            <Route index element={<Collection />} />
            <Route path="collection" element={<Collection />} />
            <Route path="staking" element={<Staking />}>
              <Route path="confirmstake" element={<ConfirmStake />} />
              <Route path="confirmunstake" element={<ConfirmUnstake />} />
              <Route
                path="confirmlegendarystake"
                element={<ConfirmLegendaryStake />}
              />
              <Route
                path="confirmlegendaryunstake"
                element={<ConfirmLegendaryUnstake />}
              />
            </Route>
            {/* <Route path="leaderboard" element={<Leaderboard />} /> */}
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
